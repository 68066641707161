<template><div><h1 id="notes-for-non-standard-linux-distributions" tabindex="-1"><a class="header-anchor" href="#notes-for-non-standard-linux-distributions"><span>Notes for non-standard Linux distributions</span></a></h1>
<div class="hint-container warning">
<p class="hint-container-title">Warning</p>
<p><strong>Advanced users only!</strong><br>
This page is not meant for troubleshooting</p>
</div>
<div class="hint-container caution">
<p class="hint-container-title">Caution</p>
<p><strong>We will not help you if you get stuck while using an unsupported distribution!</strong></p>
</div>
<p>This page contains informations useful to get non-standard or niche Distros to work.</p>
<h2 id="audio" tabindex="-1"><a class="header-anchor" href="#audio"><span>Audio</span></a></h2>
<h3 id="alsa-ucm2-for-chromebooks" tabindex="-1"><a class="header-anchor" href="#alsa-ucm2-for-chromebooks"><span>alsa-ucm2 for Chromebooks</span></a></h3>
<p>You need custon alsa-ucm2 configs that arent available upstream</p>
<p>You can get them <a href="https://github.com/WeirdTreeThing/alsa-ucm-conf-cros" target="_blank" rel="noopener noreferrer">here</a></p>
<p>If your distro isnt FHS-compliant, you can specify the ucm location via an enviromental variable</p>
<div class="language-bash line-numbers-mode" data-highlighter="prismjs" data-ext="sh" data-title="sh"><pre v-pre><code><span class="line"><span class="token builtin class-name">export</span> ALSA_CONFIG_UCM2 <span class="token operator">=</span> <span class="token string">"/share/alsa/ucm2"</span><span class="token punctuation">;</span></span>
<span class="line"></span></code></pre>
<div class="line-numbers" aria-hidden="true" style="counter-reset:line-number 0"><div class="line-number"></div></div></div><h3 id="audio-setup-modprobes" tabindex="-1"><a class="header-anchor" href="#audio-setup-modprobes"><span>Audio setup modprobes</span></a></h3>
<p>You can find the right modprobe configs by browsing <a href="https://github.com/WeirdTreeThing/chromebook-linux-audio/blob/main/setup-audio" target="_blank" rel="noopener noreferrer">the audio script</a></p>
<h2 id="keyboard-mapping" tabindex="-1"><a class="header-anchor" href="#keyboard-mapping"><span>Keyboard mapping</span></a></h2>
<h3 id="libinput-configuration" tabindex="-1"><a class="header-anchor" href="#libinput-configuration"><span>libinput configuration</span></a></h3>
<p>Put this in <code v-pre>/etc/libinput/local-overrides.quirks</code>: (<a href="https://github.com/WeirdTreeThing/cros-keyboard-map/blob/main/local-overrides.quirks" target="_blank" rel="noopener noreferrer">Source</a>)</p>
<div class="language-ini line-numbers-mode" data-highlighter="prismjs" data-ext="ini" data-title="ini"><pre v-pre><code><span class="line"><span class="token section"><span class="token punctuation">[</span><span class="token section-name selector">keyd virtual keyboard</span><span class="token punctuation">]</span></span></span>
<span class="line"><span class="token key attr-name">MatchName</span><span class="token punctuation">=</span><span class="token value attr-value">keyd virtual keyboard</span></span>
<span class="line"><span class="token key attr-name">AttrKeyboardIntegration</span><span class="token punctuation">=</span><span class="token value attr-value">internal</span></span>
<span class="line"><span class="token key attr-name">ModelTabletModeNoSuspend</span><span class="token punctuation">=</span><span class="token value attr-value">1</span></span>
<span class="line"></span></code></pre>
<div class="line-numbers" aria-hidden="true" style="counter-reset:line-number 0"><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div></div></div><h3 id="keyd-keymap" tabindex="-1"><a class="header-anchor" href="#keyd-keymap"><span>keyd keymap</span></a></h3>
<p>Install <a href="https://github.com/rvaiya/keyd" target="_blank" rel="noopener noreferrer">keyd (Github)</a></p>
<p>A configuration file can be accuired with <a href="https://github.com/WeirdTreeThing/cros-keyboard-map" target="_blank" rel="noopener noreferrer">cros-keyboard-map</a>. Some special cases have configs in the configs folder. For all other devices use <code v-pre>cros-keyboard-map.py</code>.</p>
</div></template>


