export const redirects = JSON.parse("{}")

export const routes = Object.fromEntries([
  ["/", { loader: () => import(/* webpackChunkName: "index.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/index.html.js"), meta: {"title":"What is a chrultrabook and who is this documentation for?"} }],
  ["/docs/contributing.html", { loader: () => import(/* webpackChunkName: "docs_contributing.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/contributing.html.js"), meta: {"title":"Supporting the Docs"} }],
  ["/docs/exiting-developer-mode.html", { loader: () => import(/* webpackChunkName: "docs_exiting-developer-mode.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/exiting-developer-mode.html.js"), meta: {"title":"Developer Mode"} }],
  ["/docs/faq.html", { loader: () => import(/* webpackChunkName: "docs_faq.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/faq.html.js"), meta: {"title":"FAQ"} }],
  ["/docs/terminology.html", { loader: () => import(/* webpackChunkName: "docs_terminology.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/terminology.html.js"), meta: {"title":"Terminology"} }],
  ["/docs/debugging/bugreport.html", { loader: () => import(/* webpackChunkName: "docs_debugging_bugreport.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/debugging/bugreport.html.js"), meta: {"title":"Bug Reporting"} }],
  ["/docs/debugging/", { loader: () => import(/* webpackChunkName: "docs_debugging_index.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/debugging/index.html.js"), meta: {"title":"Debugging"} }],
  ["/docs/installing/bootableusb.html", { loader: () => import(/* webpackChunkName: "docs_installing_bootableusb.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/installing/bootableusb.html.js"), meta: {"title":"Making a Bootable USB"} }],
  ["/docs/installing/distros.html", { loader: () => import(/* webpackChunkName: "docs_installing_distros.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/installing/distros.html.js"), meta: {"title":"Notes for non-standard Linux distributions"} }],
  ["/docs/installing/dualboot.html", { loader: () => import(/* webpackChunkName: "docs_installing_dualboot.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/installing/dualboot.html.js"), meta: {"title":"Partitioning Instructions for Dual Booting"} }],
  ["/docs/installing/ectool.html", { loader: () => import(/* webpackChunkName: "docs_installing_ectool.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/installing/ectool.html.js"), meta: {"title":"Using Ectool"} }],
  ["/docs/installing/", { loader: () => import(/* webpackChunkName: "docs_installing_index.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/installing/index.html.js"), meta: {"title":"Installing an OS"} }],
  ["/docs/installing/installing-linux.html", { loader: () => import(/* webpackChunkName: "docs_installing_installing-linux.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/installing/installing-linux.html.js"), meta: {"title":"Installing Linux"} }],
  ["/docs/installing/installing-macos.html", { loader: () => import(/* webpackChunkName: "docs_installing_installing-macos.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/installing/installing-macos.html.js"), meta: {"title":"Installing macOS"} }],
  ["/docs/installing/installing-windows.html", { loader: () => import(/* webpackChunkName: "docs_installing_installing-windows.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/installing/installing-windows.html.js"), meta: {"title":"Installing Windows"} }],
  ["/docs/installing/known-issues.html", { loader: () => import(/* webpackChunkName: "docs_installing_known-issues.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/installing/known-issues.html.js"), meta: {"title":"Known Issues"} }],
  ["/docs/installing/macos-firmware.html", { loader: () => import(/* webpackChunkName: "docs_installing_macos-firmware.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/installing/macos-firmware.html.js"), meta: {"title":"macOS Firmware"} }],
  ["/docs/installing/macos-hibernation.html", { loader: () => import(/* webpackChunkName: "docs_installing_macos-hibernation.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/installing/macos-hibernation.html.js"), meta: {"title":"macOS Hibernation"} }],
  ["/docs/installing/post-install.html", { loader: () => import(/* webpackChunkName: "docs_installing_post-install.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/installing/post-install.html.js"), meta: {"title":"Post Install"} }],
  ["/docs/firmware/about.html", { loader: () => import(/* webpackChunkName: "docs_firmware_about.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/firmware/about.html.js"), meta: {"title":"Types of Firmware"} }],
  ["/docs/firmware/battery.html", { loader: () => import(/* webpackChunkName: "docs_firmware_battery.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/firmware/battery.html.js"), meta: {"title":"Disabling Write Protect via Battery"} }],
  ["/docs/firmware/compiling-coreboot.html", { loader: () => import(/* webpackChunkName: "docs_firmware_compiling-coreboot.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/firmware/compiling-coreboot.html.js"), meta: {"title":"Compiling Custom Firmware"} }],
  ["/docs/firmware/developer-mode.html", { loader: () => import(/* webpackChunkName: "docs_firmware_developer-mode.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/firmware/developer-mode.html.js"), meta: {"title":"Developer Mode"} }],
  ["/docs/firmware/flashing-firmware.html", { loader: () => import(/* webpackChunkName: "docs_firmware_flashing-firmware.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/firmware/flashing-firmware.html.js"), meta: {"title":"Flashing Custom Firmware"} }],
  ["/docs/firmware/", { loader: () => import(/* webpackChunkName: "docs_firmware_index.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/firmware/index.html.js"), meta: {"title":"Flashing Custom Firmware"} }],
  ["/docs/firmware/known-issues.html", { loader: () => import(/* webpackChunkName: "docs_firmware_known-issues.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/firmware/known-issues.html.js"), meta: {"title":"Known Issues"} }],
  ["/docs/firmware/manually-flashing.html", { loader: () => import(/* webpackChunkName: "docs_firmware_manually-flashing.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/firmware/manually-flashing.html.js"), meta: {"title":"Manually Flashing ROMs"} }],
  ["/docs/firmware/recovery-mode.html", { loader: () => import(/* webpackChunkName: "docs_firmware_recovery-mode.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/firmware/recovery-mode.html.js"), meta: {"title":"Recovery Mode"} }],
  ["/docs/firmware/supported-devices.html", { loader: () => import(/* webpackChunkName: "docs_firmware_supported-devices.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/firmware/supported-devices.html.js"), meta: {"title":"Supported Devices and Platforms"} }],
  ["/docs/firmware/suzyq.html", { loader: () => import(/* webpackChunkName: "docs_firmware_suzyq.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/firmware/suzyq.html.js"), meta: {"title":"Disabling write protect with a SuzyQable"} }],
  ["/docs/firmware/system-info.html", { loader: () => import(/* webpackChunkName: "docs_firmware_system-info.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/firmware/system-info.html.js"), meta: {"title":"Finding System Info"} }],
  ["/docs/firmware/ti50-ap-ro.html", { loader: () => import(/* webpackChunkName: "docs_firmware_ti50-ap-ro.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/firmware/ti50-ap-ro.html.js"), meta: {"title":"Disabling RO firmware protection on Ti50"} }],
  ["/docs/firmware/updating-firmware.html", { loader: () => import(/* webpackChunkName: "docs_firmware_updating-firmware.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/firmware/updating-firmware.html.js"), meta: {"title":"Updating Custom Firmware"} }],
  ["/docs/firmware/ventoy.html", { loader: () => import(/* webpackChunkName: "docs_firmware_ventoy.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/firmware/ventoy.html.js"), meta: {"title":"Installing Ventoy to a USB drive with chromeOS"} }],
  ["/docs/firmware/write-protect.html", { loader: () => import(/* webpackChunkName: "docs_firmware_write-protect.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/firmware/write-protect.html.js"), meta: {"title":"Disabling Write Protect"} }],
  ["/docs/reverting/booting-recovery-usb.html", { loader: () => import(/* webpackChunkName: "docs_reverting_booting-recovery-usb.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/reverting/booting-recovery-usb.html.js"), meta: {"title":"Booting the Recovery USB"} }],
  ["/docs/reverting/flashing-stock.html", { loader: () => import(/* webpackChunkName: "docs_reverting_flashing-stock.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/reverting/flashing-stock.html.js"), meta: {"title":"Flashing Stock Firmware"} }],
  ["/docs/reverting/", { loader: () => import(/* webpackChunkName: "docs_reverting_index.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/reverting/index.html.js"), meta: {"title":"Reverting to Stock chromeOS"} }],
  ["/docs/reverting/making-recovery-usb.html", { loader: () => import(/* webpackChunkName: "docs_reverting_making-recovery-usb.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/reverting/making-recovery-usb.html.js"), meta: {"title":"Making a Recovery USB"} }],
  ["/docs/unbricking/", { loader: () => import(/* webpackChunkName: "docs_unbricking_index.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/unbricking/index.html.js"), meta: {"title":"Unbricking"} }],
  ["/docs/unbricking/unbrick-ch341a.html", { loader: () => import(/* webpackChunkName: "docs_unbricking_unbrick-ch341a.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/unbricking/unbrick-ch341a.html.js"), meta: {"title":"Unbricking/Flashing with a ch341a USB programmer"} }],
  ["/docs/unbricking/unbrick-flipper.html", { loader: () => import(/* webpackChunkName: "docs_unbricking_unbrick-flipper.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/unbricking/unbrick-flipper.html.js"), meta: {"title":"Unbricking with a Flipper Zero"} }],
  ["/docs/unbricking/unbrick-suzyq.html", { loader: () => import(/* webpackChunkName: "docs_unbricking_unbrick-suzyq.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/docs/unbricking/unbrick-suzyq.html.js"), meta: {"title":"Unbricking/Flashing with a Suzy-Q cable"} }],
  ["/404.html", { loader: () => import(/* webpackChunkName: "404.html" */"/home/runner/work/docs/docs/src/.vuepress/.temp/pages/404.html.js"), meta: {"title":""} }],
]);
