<template><div><h1 id="using-ectool" tabindex="-1"><a class="header-anchor" href="#using-ectool"><span>Using Ectool</span></a></h1>
<p><code v-pre>ectool</code> is a utility that is used to interface with the ChromeOS Embedded Controller. It is used to communicate with the embedded controller from userspace and vice versa.</p>
<h2 id="installing-ectool" tabindex="-1"><a class="header-anchor" href="#installing-ectool"><span>Installing Ectool</span></a></h2>
<p>If you use Fedora or Ultramarine Linux, you can install the <code v-pre>chromium-ectool</code> package from the <a href="https://terra.fyralabs.com" target="_blank" rel="noopener noreferrer">Terra repository</a>.</p>
<p>Otherwise, you can download <code v-pre>ectool</code> for Linux from <a href="https://files.tree123.org/utils/x86_64/gnu/ectool" target="_blank" rel="noopener noreferrer">here</a>. Alternatively there is a <a href="https://files.tree123.org/utils/x86_64/musl/ectool" target="_blank" rel="noopener noreferrer">Alpine Linux / musl version</a>.</p>
<p>For Windows users, <code v-pre>ectool</code> is installed when you install CoolStar's Chrome EC driver and is located in <code v-pre>C:\Program Files\crosec\ectool.exe</code>.</p>
<h2 id="uses" tabindex="-1"><a class="header-anchor" href="#uses"><span>Uses</span></a></h2>
<div class="hint-container tip">
<p class="hint-container-title">Tips</p>
<p>Run <code v-pre>ectool help</code> for a full list of commands.</p>
</div>
<p>Some common uses of <code v-pre>ectool</code> include:</p>
<ul>
<li>Accessing the EC console: <code v-pre>ectool console</code></li>
<li>Controlling the fan with <code v-pre>ectool fanduty &lt;percent&gt;</code></li>
<li>Set the keyboard backlight in devices that have it: <code v-pre>ectool pwmsetkblight &lt;percent&gt;</code></li>
</ul>
</div></template>


