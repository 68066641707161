<template><div><h1 id="post-install" tabindex="-1"><a class="header-anchor" href="#post-install"><span>Post Install</span></a></h1>
<div class="hint-container tip">
<p class="hint-container-title">Tips</p>
<p>Install <a href="https://github.com/death7654/Chrultrabook-Tools/releases" target="_blank" rel="noopener noreferrer">Chrultrabook Tools</a> for fan controls, keyboard backlight controls, system information and more.</p>
</div>
<h2 id="windows" tabindex="-1"><a class="header-anchor" href="#windows"><span>Windows</span></a></h2>
<h3 id="drivers-and-qol-improvements" tabindex="-1"><a class="header-anchor" href="#drivers-and-qol-improvements"><span>Drivers and QOL improvements</span></a></h3>
<ul>
<li>Compress the install with <code v-pre>compact.exe /compactos:always</code> in a Command Prompt with admin privileges if necessary.</li>
<li>Automatic
<ul>
<li>Use <a href="https://github.com/death7654/Chromebook-Driver-Installer" target="_blank" rel="noopener noreferrer">One Click Driver Installer</a> To install drivers</li>
</ul>
</li>
<li>Manual
<ul>
<li>Install <a href="https://coolstar.org/chromebook/windows-install.html" target="_blank" rel="noopener noreferrer">CoolStar's drivers</a> if you haven't already</li>
</ul>
</li>
<li>Buy audio and Thunderbolt/USB4 drivers (if needed), see <a href="https://coolstar.org/chromebook/driverlicense/login.html" target="_blank" rel="noopener noreferrer">CoolStar's driver portal</a></li>
</ul>
<h3 id="celes-post-install-workaround-windows" tabindex="-1"><a class="header-anchor" href="#celes-post-install-workaround-windows"><span>CELES Post Install Workaround (Windows)</span></a></h3>
<ol>
<li>Boot the Windows install media you used to install Windows.</li>
<li>Click &quot;Repair my computer&quot; and select the Command Prompt option.</li>
<li>Type <code v-pre>bcdedit /set {default} useplatformclock true</code> and press Enter.</li>
<li>Reboot</li>
</ol>
<h2 id="linux" tabindex="-1"><a class="header-anchor" href="#linux"><span>Linux</span></a></h2>
<h3 id="general-qol-fixes" tabindex="-1"><a class="header-anchor" href="#general-qol-fixes"><span>General QOL Fixes</span></a></h3>
<ul>
<li>Run the <a href="https://github.com/WeirdTreeThing/chromebook-linux-audio" target="_blank" rel="noopener noreferrer">audio script</a> if you haven't already.</li>
<li>Run the <a href="https://github.com/WeirdTreeThing/cros-keyboard-map" target="_blank" rel="noopener noreferrer">cros-keyboard-map</a> script to remap top row FX keys.</li>
</ul>
<h3 id="fixing-usb-c-on-tigerlake-and-alderlake" tabindex="-1"><a class="header-anchor" href="#fixing-usb-c-on-tigerlake-and-alderlake"><span>Fixing USB C on TigerLake and AlderLake</span></a></h3>
<p>Distros that use dracut for initramfs, such as Fedora, have an issue which prevents the <code v-pre>cros_ec_typec</code> kernel driver from loading correctly. You can fix it with the following steps.</p>
<ol>
<li>
<p>Create a file called <code v-pre>chromebook_typec.conf</code> file in <code v-pre>/etc/dracut.conf.d/</code></p>
</li>
<li>
<p>Paste the following contents into the file:</p>
<div class="language-txt line-numbers-mode" data-highlighter="prismjs" data-ext="txt" data-title="txt"><pre v-pre><code><span class="line">omit_drivers+=" intel_pmc_mux "</span>
<span class="line"></span></code></pre>
<div class="line-numbers" aria-hidden="true" style="counter-reset:line-number 0"><div class="line-number"></div></div></div></li>
<li>
<p>Rebuild your initramfs with <code v-pre>sudo dracut --force</code>.</p>
</li>
</ol>
<h3 id="celes-post-install-workaround-possible-linux" tabindex="-1"><a class="header-anchor" href="#celes-post-install-workaround-possible-linux"><span>CELES Post Install Workaround Possible (Linux)</span></a></h3>
<p>If you experience issues in applications such as Parsec, or encounter disruptive freezes, adding the kernel parameters <code v-pre>clocksource=hpet hpet=force</code> may fix your problem. The following instructions assume you are using GRUB, and will be different for other bootloaders. Do your own research on how to set kernel parameters in your bootloader if these do not apply.</p>
<ol>
<li>Edit <code v-pre>/etc/default/grub</code> with your preferred text editor (e.g. nano).</li>
<li>Add <code v-pre>clocksource=hpet hpet=force</code> inside of GRUB_CMDLINE_LINUX_DEFAULT and save your changes. If you exclude either parameter, this will not work. Use sudo, su, or doas if necessary.</li>
<li>Type <code v-pre>grub-mkconfig -o /boot/grub/grub.cfg</code> or <code v-pre>update-grub</code> into a terminal and press Enter. Use sudo, su, or doas if necessary.</li>
<li>Reboot</li>
</ol>
<h3 id="fixing-stylus-orientation-in-gnome" tabindex="-1"><a class="header-anchor" href="#fixing-stylus-orientation-in-gnome"><span>Fixing stylus orientation in Gnome</span></a></h3>
<p>Some Chromebooks come with USI styluses. They work fine in the newest versions of KDE Plasma, but they have a rotation issue in GNOME. The issue is that the stylus does not rotate with the screen, so it is only usable in one orientation. To fix this, we have to add an libinput override.</p>
<ol>
<li>
<p>Get the device ID of your stylus
This information can either be read from cbmem or libwacom.
To get the ID from libwacom run this script https://github.com/linuxwacom/libwacom/blob/master/tools/show-stylus.py.</p>
<div class="language-bash line-numbers-mode" data-highlighter="prismjs" data-ext="sh" data-title="sh"><pre v-pre><code><span class="line">tux@fedora:~/Downloads/libwacom/tools$ <span class="token function">sudo</span> python ./show-stylus.py </span>
<span class="line">Using <span class="token string">"GDIX0000:00 27C6:0E0C Stylus"</span><span class="token builtin class-name">:</span> /dev/input/event5</span>
<span class="line">Using stylus file<span class="token punctuation">(</span>s<span class="token punctuation">)</span>: /usr/share/libwacom/libwacom.stylus</span>
<span class="line">Tool <span class="token function">id</span> 0x1 serial 0x0 in-proximity: False </span>
<span class="line">^CTerminating</span>
<span class="line">Suggested line <span class="token keyword">for</span> .tablet file:</span>
<span class="line"><span class="token assign-left variable">Styli</span><span class="token operator">=</span>isdv4-aes</span>
<span class="line"></span></code></pre>
<div class="line-numbers" aria-hidden="true" style="counter-reset:line-number 0"><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div></div></div><p>In this case, the device ID is <code v-pre>GDIX0000:00 27C6:0E0C Stylus</code>.</p>
</li>
<li>
<p>Create a .tablet file for libwacom</p>
<div class="language-bash line-numbers-mode" data-highlighter="prismjs" data-ext="sh" data-title="sh"><pre v-pre><code><span class="line"><span class="token function">sudo</span> <span class="token function">mkdir</span> <span class="token parameter variable">-p</span> /etc/libwacom/</span>
<span class="line"><span class="token function">sudo</span> <span class="token function">nano</span> /etc/libwacom/google-<span class="token punctuation">{</span>your board name<span class="token punctuation">}</span>.tablet</span>
<span class="line"></span></code></pre>
<div class="line-numbers" aria-hidden="true" style="counter-reset:line-number 0"><div class="line-number"></div><div class="line-number"></div></div></div><p>Add the following content to the file:</p>
<div class="language-bash line-numbers-mode" data-highlighter="prismjs" data-ext="sh" data-title="sh"><pre v-pre><code><span class="line"><span class="token punctuation">[</span>Device<span class="token punctuation">]</span></span>
<span class="line"><span class="token assign-left variable">Name</span><span class="token operator">=</span><span class="token punctuation">{</span>your device id<span class="token punctuation">}</span></span>
<span class="line"><span class="token assign-left variable">ModelName</span><span class="token operator">=</span></span>
<span class="line"><span class="token assign-left variable">DeviceMatch</span><span class="token operator">=</span>i2c:<span class="token punctuation">{</span>the hardware <span class="token function">id</span> of your stylus lowercased <span class="token punctuation">(</span>in this <span class="token keyword">case</span> 27c6:0e0c<span class="token punctuation">)</span><span class="token punctuation">}</span></span>
<span class="line"><span class="token assign-left variable">Class</span><span class="token operator">=</span>ISDV4</span>
<span class="line"><span class="token assign-left variable">Width</span><span class="token operator">=</span><span class="token number">9</span></span>
<span class="line"><span class="token assign-left variable">Height</span><span class="token operator">=</span><span class="token number">5</span></span>
<span class="line"><span class="token assign-left variable">IntegratedIn</span><span class="token operator">=</span>Display<span class="token punctuation">;</span>System</span>
<span class="line"><span class="token comment">#Styli=isdv4-aes</span></span>
<span class="line"><span class="token assign-left variable">Styli</span><span class="token operator">=</span>@generic-no-eraser</span>
<span class="line"></span>
<span class="line"><span class="token punctuation">[</span>Features<span class="token punctuation">]</span></span>
<span class="line"><span class="token assign-left variable">Stylus</span><span class="token operator">=</span>true</span>
<span class="line"><span class="token assign-left variable">Touch</span><span class="token operator">=</span>false</span>
<span class="line"></span></code></pre>
<div class="line-numbers" aria-hidden="true" style="counter-reset:line-number 0"><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div></div></div></li>
<li>
<p>Create the libinput override</p>
<div class="language-bash line-numbers-mode" data-highlighter="prismjs" data-ext="sh" data-title="sh"><pre v-pre><code><span class="line"><span class="token function">sudo</span> <span class="token function">mkdir</span> <span class="token parameter variable">-p</span> /etc/libinput/</span>
<span class="line"><span class="token function">sudo</span> <span class="token function">nano</span> /etc/libinput/local-overrides.quirks</span>
<span class="line"></span></code></pre>
<div class="line-numbers" aria-hidden="true" style="counter-reset:line-number 0"><div class="line-number"></div><div class="line-number"></div></div></div><p>Add the following content to the file:</p>
<div class="language-bash line-numbers-mode" data-highlighter="prismjs" data-ext="sh" data-title="sh"><pre v-pre><code><span class="line"><span class="token punctuation">[</span>Google Chromebook <span class="token punctuation">{</span>your board name<span class="token punctuation">}</span> Stylus Digitizer<span class="token punctuation">]</span></span>
<span class="line"><span class="token assign-left variable">MatchUdevType</span><span class="token operator">=</span>tablet</span>
<span class="line"><span class="token assign-left variable">MatchDeviceTree</span><span class="token operator">=</span>*<span class="token punctuation">{</span>your board name<span class="token punctuation">}</span>*</span>
<span class="line"><span class="token assign-left variable">MatchBus</span><span class="token operator">=</span>i2c</span>
<span class="line"><span class="token assign-left variable">ModelChromebook</span><span class="token operator">=</span><span class="token number">1</span></span>
<span class="line"><span class="token assign-left variable">AttrPressureRange</span><span class="token operator">=</span><span class="token number">1100</span>:1000</span>
<span class="line"></span></code></pre>
<div class="line-numbers" aria-hidden="true" style="counter-reset:line-number 0"><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div></div></div></li>
<li>
<p>Update the libwacom database and restart the system</p>
<div class="language-bash line-numbers-mode" data-highlighter="prismjs" data-ext="sh" data-title="sh"><pre v-pre><code><span class="line"><span class="token function">sudo</span> libwacom-update-db</span>
<span class="line"><span class="token function">reboot</span></span>
<span class="line"></span></code></pre>
<div class="line-numbers" aria-hidden="true" style="counter-reset:line-number 0"><div class="line-number"></div><div class="line-number"></div></div></div></li>
</ol>
<h4 id="example-files-for-jinlon" tabindex="-1"><a class="header-anchor" href="#example-files-for-jinlon"><span>Example Files for Jinlon</span></a></h4>
<p>Here are the example files for the Jinlon Chromebook. The device ID is <code v-pre>GDIX0000:00 27C6:0E0C Stylus</code>.</p>
<p><code v-pre>/etc/libwacom/google-jinlon.tablet</code></p>
<div class="language-bash line-numbers-mode" data-highlighter="prismjs" data-ext="sh" data-title="sh"><pre v-pre><code><span class="line"><span class="token punctuation">[</span>Device<span class="token punctuation">]</span></span>
<span class="line"><span class="token assign-left variable">Name</span><span class="token operator">=</span>GDIX0000:00 27C6:0E0C Stylus</span>
<span class="line"><span class="token assign-left variable">ModelName</span><span class="token operator">=</span></span>
<span class="line"><span class="token assign-left variable">DeviceMatch</span><span class="token operator">=</span>i2c:27c6:0e0c</span>
<span class="line"><span class="token assign-left variable">Class</span><span class="token operator">=</span>ISDV4</span>
<span class="line"><span class="token assign-left variable">Width</span><span class="token operator">=</span><span class="token number">9</span></span>
<span class="line"><span class="token assign-left variable">Height</span><span class="token operator">=</span><span class="token number">5</span></span>
<span class="line"><span class="token assign-left variable">IntegratedIn</span><span class="token operator">=</span>Display<span class="token punctuation">;</span>System</span>
<span class="line"><span class="token comment">#Styli=isdv4-aes</span></span>
<span class="line"><span class="token assign-left variable">Styli</span><span class="token operator">=</span>@generic-no-eraser</span>
<span class="line"></span>
<span class="line"><span class="token punctuation">[</span>Features<span class="token punctuation">]</span></span>
<span class="line"><span class="token assign-left variable">Stylus</span><span class="token operator">=</span>true</span>
<span class="line"><span class="token assign-left variable">Touch</span><span class="token operator">=</span>false</span>
<span class="line"></span></code></pre>
<div class="line-numbers" aria-hidden="true" style="counter-reset:line-number 0"><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div></div></div><p><code v-pre>/etc/libinput/local-overrides.quirks</code></p>
<div class="language-bash line-numbers-mode" data-highlighter="prismjs" data-ext="sh" data-title="sh"><pre v-pre><code><span class="line"><span class="token punctuation">[</span>Google Chromebook Jinlon Stylus Digitizer<span class="token punctuation">]</span></span>
<span class="line"><span class="token assign-left variable">MatchUdevType</span><span class="token operator">=</span>tablet</span>
<span class="line"><span class="token assign-left variable">MatchDeviceTree</span><span class="token operator">=</span>*jinlon*</span>
<span class="line"><span class="token assign-left variable">MatchBus</span><span class="token operator">=</span>i2c</span>
<span class="line"><span class="token assign-left variable">ModelChromebook</span><span class="token operator">=</span><span class="token number">1</span></span>
<span class="line"><span class="token assign-left variable">AttrPressureRange</span><span class="token operator">=</span><span class="token number">1100</span>:1000</span>
<span class="line"></span></code></pre>
<div class="line-numbers" aria-hidden="true" style="counter-reset:line-number 0"><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div></div></div><h4 id="consider-upstreaming-your-changes-to-libwacom" tabindex="-1"><a class="header-anchor" href="#consider-upstreaming-your-changes-to-libwacom"><span>Consider Upstreaming Your Changes to libwacom</span></a></h4>
<p>Please consider upstreaming your changes to <a href="https://github.com/linuxwacom/libwacom" target="_blank" rel="noopener noreferrer">libwacom</a> and <a href="https://github.com/linuxwacom/wacom-hid-descriptors" target="_blank" rel="noopener noreferrer">wacom-hid-descriptors</a>. This will help other users with the same device as you.</p>
<h2 id="macos" tabindex="-1"><a class="header-anchor" href="#macos"><span>macOS</span></a></h2>
<ul>
<li><a href="https://dortania.github.io/OpenCore-Post-Install/cosmetic/verbose.html#macos-decluttering" target="_blank" rel="noopener noreferrer">Remove Verbose</a></li>
<li><a href="https://dortania.github.io/OpenCore-Post-Install/cosmetic/gui.html#setting-up-opencore-s-gui" target="_blank" rel="noopener noreferrer">OpenCore GUI Setup</a></li>
<li>Disable <code v-pre>DevirtualiseMmio</code> if you are on CometLake (10th gen)</li>
</ul>
</div></template>


