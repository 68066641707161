<template><div><h1 id="flashing-custom-firmware" tabindex="-1"><a class="header-anchor" href="#flashing-custom-firmware"><span>Flashing Custom Firmware</span></a></h1>
<div class="hint-container caution">
<p class="hint-container-title">Caution</p>
<p>You are about to make <strong>UNOFFICIAL</strong> changes to your device! This will <strong>VOID ANY WARRANTY</strong> that came with your original purchase. We provide <strong>ZERO WARRANTY</strong> if you encounter bugs, brick your device, lose your job because the alarm clock didn't go off, or if a thermonuclear war starts. <strong>YOU are doing all changes at your own RISK</strong>!</p>
</div>
<div class="hint-container caution">
<p class="hint-container-title">Caution</p>
<p>Do not continue if your device is currently owned or enrolled by a organization, such as a school or employer, unless you were given <strong>DIRECT PERMISSION to do so.</strong></p>
</div>
<h2 id="prerequisites" tabindex="-1"><a class="header-anchor" href="#prerequisites"><span>Prerequisites</span></a></h2>
<ul>
<li>Know your hardware
<ul>
<li>Your CPU model and generation</li>
<li>Your storage devices (HDD/SSD, eMMC, NVMe, etc.)</li>
<li><strong>Your boardname</strong>
<ul>
<li>For example, the boardname for Asus Chromebook C302CA is <code v-pre>Cave</code></li>
</ul>
</li>
<li>If you do not know where to find this info, take a look at the <RouteLink to="/docs/firmware/system-info.html">System Info Page</RouteLink>.</li>
</ul>
</li>
<li><strong>A basic knowledge of command lines and how to use a terminal/command prompt.</strong>
<ul>
<li>We cannot help you if you do not know how to go to a directory or delete a file from a terminal.</li>
</ul>
</li>
<li>A minimum of an 8GB USB drive or SD Card for creating a bootable OS installer.</li>
<li>A second device is recommended, and required in some cases.</li>
</ul>
<div class="hint-container tip">
<p class="hint-container-title">NOTE</p>
<p>Only x86_64 devices are currently supported.</p>
</div>
<div class="hint-container tip">
<p class="hint-container-title">Tips</p>
<p>If you only have one device, please be sure to <RouteLink to="/docs/firmware/ventoy.html">set up a USB with your OS to install</RouteLink> before flashing.</p>
</div>
<h2 id="asking-for-help-properly" tabindex="-1"><a class="header-anchor" href="#asking-for-help-properly"><span>Asking for help properly</span></a></h2>
<p>If you are facing a issue, please read the <RouteLink to="/docs/faq.html">FAQ</RouteLink> first.</p>
<div class="hint-container tip">
<p class="hint-container-title">Tips</p>
<p>When asking for help, ask in English. We can't help you if we do not understand the question. Using a translator is fine.</p>
</div>
<p><strong>Do not use manufacturer's model name or serial number when asking for help</strong> (i.e: HP Chromebook 14a), it doesn't help with identifying the machine. Provide the boardname, otherwise your support request will be ignored.</p>
<p>If you do not know where to find your boardname, take a look <RouteLink to="/docs/firmware/system-info.html">here</RouteLink>.</p>
</div></template>


