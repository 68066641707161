<template><div><h1 id="types-of-firmware" tabindex="-1"><a class="header-anchor" href="#types-of-firmware"><span>Types of Firmware</span></a></h1>
<h2 id="rw-legacy" tabindex="-1"><a class="header-anchor" href="#rw-legacy"><span>RW_LEGACY</span></a></h2>
<ul>
<li>Updates/replaces the stock RW_LEGACY payload (SeaBIOS or edk2) included on many older models; supplements the ChromeOS / secure boot payload (depthcharge)</li>
<li>Leaves all stock functionality intact, including the Developer Mode boot screen and Recovery Mode functionality</li>
<li>Allows device to dual-boot linux alongside ChromeOS</li>
<li>Does not require disabling write-protect</li>
<li>Carries zero risk of bricking the device</li>
<li>On many models, includes bugfixes and enables additional functionality</li>
</ul>
<div class="hint-container warning">
<p class="hint-container-title">Warning</p>
<p>Do not run Windows on RW_LEGACY. The RW_LEGACY firmware is for users who want to dual-boot ChromeOS + Linux, or users who want to run Linux without having to open the device/disable the firmware write-protect (and are OK with the accompanying limitations).</p>
</div>
<h2 id="uefi-full-rom" tabindex="-1"><a class="header-anchor" href="#uefi-full-rom"><span>(UEFI) Full ROM</span></a></h2>
<ul>
<li>A complete firmware image which includes updated/customized versions of the hardware init component (coreboot) and UEFI boot payload (edk2)</li>
<li>Removes the developer mode boot (&quot;OS verification is OFF&quot;) screen</li>
<li>Completely removes the ability to run ChromeOS (and ChromeOS Recovery Mode), creating a small risk of bricking your device</li>
<li>Offers the best support for booting all OSes besides ChromeOS</li>
<li>Adds full hardware support for virtualization (vmx / VT-x)</li>
<li>Fixes many bugs and/or idiosyncrasies associated with the stock firmware</li>
<li>UEFI firmware contains updated EC firmware as well, which brings additional fixes on most Chromebooks (mainly keyboard related)</li>
<li>Requires installation of a UEFI-compatible OS after flashing</li>
<li>Essentially turns your ChromeOS device into a &quot;regular&quot; PC / laptop</li>
</ul>
<div class="hint-container tip">
<p class="hint-container-title">Tips</p>
<p>The (UEFI) Full ROM firmware is the best option for all users who no longer need/want to run ChromeOS (ie, want to run Linux/Windows/macOS exclusively), and who do not mind disabling write-protection on their device.</p>
</div>
</div></template>


