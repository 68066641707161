<template><div><h1 id="unbricking" tabindex="-1"><a class="header-anchor" href="#unbricking"><span>Unbricking</span></a></h1>
<p>If your device's firmware got into a bad state (will not POST), you can try unbricking it.</p>
<div class="hint-container tip">
<p class="hint-container-title">Tips</p>
<p>Try performing a EC reset beforehand to see if your device can recover.</p>
</div>
<div class="hint-container tip">
<p class="hint-container-title">Tips</p>
<p>If your device has a Ti50 chip and you didn't disable RO verification before flashing, follow <RouteLink to="/docs/firmware/ti50-ap-ro.html#recovering-a-device-bricked-due-to-ro-verification">this guide</RouteLink> to recover it.</p>
</div>
<hr>
<p>Here is the following ways that you can unbrick your device:</p>
<h2 id="unbricking-with-a-ch341a-usb-programmer" tabindex="-1"><a class="header-anchor" href="#unbricking-with-a-ch341a-usb-programmer"><span><a href="unbrick-ch341a">Unbricking with a ch341a USB programmer</a></span></a></h2>
<h2 id="unbricking-with-a-suzy-q-cable" tabindex="-1"><a class="header-anchor" href="#unbricking-with-a-suzy-q-cable"><span><a href="unbrick-suzyq">Unbricking with a Suzy-Q cable</a></span></a></h2>
<h2 id="unbricking-with-a-flipper-zero" tabindex="-1"><a class="header-anchor" href="#unbricking-with-a-flipper-zero"><span><a href="unbrick-flipper">Unbricking with a flipper Zero</a></span></a></h2>
<hr>
<p>Adapted from <a href="https://wiki.mrchromebox.tech/Unbricking">https://wiki.mrchromebox.tech/Unbricking</a></p>
</div></template>


