<template><div><h1 id="booting-the-recovery-usb" tabindex="-1"><a class="header-anchor" href="#booting-the-recovery-usb"><span>Booting the Recovery USB</span></a></h1>
<div class="hint-container tip">
<p class="hint-container-title">Tips</p>
<p>If recovery fails, <RouteLink to="/docs/firmware/write-protect.html">disable write protect</RouteLink> since recovery also attempts to update the firmware.</p>
</div>
<div class="hint-container caution">
<p class="hint-container-title">Caution</p>
<p>Going through the recovery process will wipe <strong>all</strong> data on the internal drive.</p>
</div>
<ol>
<li>
<p>Enter <RouteLink to="/docs/firmware/recovery-mode.html">recovery mode</RouteLink>.</p>
</li>
<li>
<p>Plug in your recovery USB drive.</p>
</li>
<li>
<p>Begin the recovery process.</p>
</li>
<li>
<p>Once its done, set up the system like normal.</p>
</li>
</ol>
</div></template>


