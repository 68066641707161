<template><div><h1 id="installing-an-os" tabindex="-1"><a class="header-anchor" href="#installing-an-os"><span>Installing an OS</span></a></h1>
<div class="hint-container tip">
<p class="hint-container-title">Tips</p>
<p>Be sure to check the <RouteLink to="/docs/firmware/supported-devices.html">supported devices page</RouteLink> to see what you can run.</p>
</div>
<h2 id="windows" tabindex="-1"><a class="header-anchor" href="#windows"><span>Windows</span></a></h2>
<h3 id="pros-of-windows" tabindex="-1"><a class="header-anchor" href="#pros-of-windows"><span>Pros of Windows</span></a></h3>
<ul>
<li>Works on most platforms with minimal tweaking¹</li>
<li>Generally has better application support</li>
<li>Better game support</li>
</ul>
<h3 id="cons-of-windows" tabindex="-1"><a class="header-anchor" href="#cons-of-windows"><span>Cons of Windows</span></a></h3>
<ul>
<li>Audio driver is paid (10 USD) for Skylake (6th generation) and newer devices</li>
<li>USB4/Thunderbolt driver is paid (20 USD) for <strong>TigerLake</strong> (11th generation Intel processors) and <strong>newer</strong> generations on devices that require them</li>
<li>Requires the user to install many drivers</li>
<li>AVS and SOF users may experience instability at times</li>
</ul>
<h3 id="installing-windows-→" tabindex="-1"><a class="header-anchor" href="#installing-windows-→"><span><RouteLink to="/docs/installing/installing-windows.html">Installing Windows →</RouteLink></span></a></h3>
<br>
<p>¹Some devices such as CELES need workarounds. Refer to the <RouteLink to="/docs/installing/known-issues.html">known issues page</RouteLink> to check whether you need patches.</p>
<h2 id="linux" tabindex="-1"><a class="header-anchor" href="#linux"><span>Linux</span></a></h2>
<h3 id="pros-of-linux" tabindex="-1"><a class="header-anchor" href="#pros-of-linux"><span>Pros of Linux</span></a></h3>
<ul>
<li>Free audio support</li>
<li>Lightweight and optimal for Chrultrabooks with low storage / slow CPUs</li>
<li>Most hardware (biggest exception is audio) will work out of the box</li>
</ul>
<h3 id="cons-of-linux" tabindex="-1"><a class="header-anchor" href="#cons-of-linux"><span>Cons of Linux</span></a></h3>
<ul>
<li>
<p>Potentially difficult for a new user (depending on the distro of choice)</p>
</li>
<li>
<p>Requires some work on the end user (running audio script, configuring keyboard mapping)</p>
</li>
<li>
<p>AVS and SOF users experience instability at times</p>
<div class="hint-container warning">
<p class="hint-container-title">Warning</p>
<p>Ubuntu and Ubuntu-based distributions <strong>may have issues</strong> and are <strong>not supported</strong>.</p>
</div>
</li>
</ul>
<br>
<h3 id="installing-linux-→" tabindex="-1"><a class="header-anchor" href="#installing-linux-→"><span><RouteLink to="/docs/installing/installing-linux.html">Installing Linux →</RouteLink></span></a></h3>
<h2 id="macos" tabindex="-1"><a class="header-anchor" href="#macos"><span>macOS</span></a></h2>
<h3 id="pros-of-macos" tabindex="-1"><a class="header-anchor" href="#pros-of-macos"><span>Pros of macOS</span></a></h3>
<ul>
<li>OS experience is near identical to a Mac</li>
</ul>
<h3 id="cons-of-macos" tabindex="-1"><a class="header-anchor" href="#cons-of-macos"><span>Cons of macOS</span></a></h3>
<ul>
<li>Battery life is subpar</li>
<li>Hardware based DRM (e.g AppleTV.app) is broken</li>
<li>No audio, mic, or 3.5mm support¹</li>
<li>Doesn't support all devices.</li>
<li>Often takes up to a week or longer to set up.</li>
<li>Limited support</li>
</ul>
<div class="hint-container tip">
<p class="hint-container-title">Tips</p>
<p>Any Chromebook with an ARM, Celeron or Pentium CPU is not supported. AMD Ryzen might have support in the future.</p>
</div>
<h3 id="installing-macos-→" tabindex="-1"><a class="header-anchor" href="#installing-macos-→"><span><RouteLink to="/docs/installing/installing-macos.html">Installing macOS →</RouteLink></span></a></h3>
</div></template>


