<template><div><h1 id="supported-devices-and-platforms" tabindex="-1"><a class="header-anchor" href="#supported-devices-and-platforms"><span>Supported Devices and Platforms</span></a></h1>
<p>Supported devices are listed in the table below, grouped by platform/family. If your device is not listed, it is not supported. Support is determined entirely and exclusively by the boardname. Use your Chromebook's boardname to see if it has support.</p>
<h2 id="determining-device-support" tabindex="-1"><a class="header-anchor" href="#determining-device-support"><span>Determining Device Support</span></a></h2>
<p>What's in a name? All ChromeOS devices have a board/device name, which determines which firmware, OS build, etc a device uses. The board name is listed at the bottom of both the Recovery Mode and Developer Mode screens, as part of the hardware ID (HWID).</p>
<p>The chart below provides a full list of all ChromeOS devices currently supported by Chrultrabook, the firmware type available for each, and notes for Windows, Linux, and macOS operating systems.</p>
<p>In most cases, all of the devices in a given platform/family are supported, but sometimes there are exceptions (especially with older or newer devices).</p>
<h3 id="os-support" tabindex="-1"><a class="header-anchor" href="#os-support"><span>OS Support</span></a></h3>
<p>A device having firmware available (either RW_LEGACY or UEFI Full ROM) does not imply any level of functionality when running an OS other than ChromeOS. Some devices/platforms are better supported in some Linux distros vs others. Some devices/platforms are better supported under Windows than others.</p>
<h2 id="supported-platforms" tabindex="-1"><a class="header-anchor" href="#supported-platforms"><span>Supported Platforms</span></a></h2>
<table>
<thead>
<tr>
<th>Intel</th>
<th>AMD</th>
<th>ARM</th>
</tr>
</thead>
<tbody>
<tr>
<td>Intel platforms have good support for both Linux and Windows. Some have support for macOS.</td>
<td>Stoneyridge support in Windows is questionable, and installing a custom kernel is required to get working audio in Linux. Ryzen has support for both Linux and Windows. MacOS is <strong>untested</strong> on AMD platforms.</td>
<td>Currently unsupported (WIP). <a href="https://wiki.postmarketos.org/wiki/Chrome_OS_devices" target="_blank" rel="noopener noreferrer">PostmarketOS</a> has support for many ARM Chromebooks.</td>
</tr>
</tbody>
</table>
<h2 id="firmware-and-os-support" tabindex="-1"><a class="header-anchor" href="#firmware-and-os-support"><span>Firmware and OS Support</span></a></h2>
<div class="hint-container tip">
<p class="hint-container-title">Tips</p>
<p>If your device isn't listed, it doesn't have support.</p>
</div>
<div class="hint-container tip">
<p class="hint-container-title">Tips</p>
<p>If you are on a smaller screen, scroll sideways to see whole table.</p>
</div>
<AddScript script-url="../../supported-devices.js"/><p>Search: <input type="text" class="deviceSearch"></p>
<div class="deviceTable">Loading...</div>
</div></template>


