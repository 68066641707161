<template><div><h1 id="reverting-to-stock-chromeos" tabindex="-1"><a class="header-anchor" href="#reverting-to-stock-chromeos"><span>Reverting to Stock chromeOS</span></a></h1>
<div class="hint-container warning">
<p class="hint-container-title">Warning</p>
<p>If your device has hit AUE (Auto Update Expiration), you will not be able to revert to chromeOS using the MrChromebox script. You will need to <RouteLink to="/docs/firmware/manually-flashing.html">flash manually</RouteLink>.</p>
</div>
<h2 id="requirements" tabindex="-1"><a class="header-anchor" href="#requirements"><span>Requirements</span></a></h2>
<ul>
<li>A PC or laptop to make a ChromeOS recovery USB.</li>
<li>An external drive like a USB drive or SD card that is at least 8GB.</li>
<li>Another external drive that contains your stock ROM backup (If backup is avaliable).</li>
<li>An understanding that this guide has the potential to brick your device.</li>
</ul>
<p>You can revert your firmware back to stock and reinstall chromeOS.</p>
<p>This is useful if your planning to sell your device.</p>
</div></template>


