<template><div><h1 id="flashing-custom-firmware" tabindex="-1"><a class="header-anchor" href="#flashing-custom-firmware"><span>Flashing Custom Firmware</span></a></h1>
<p>Refer to <a href="about">this page</a> for an overview of both RW_LEGACY and UEFI.</p>
<div class="hint-container caution">
<p class="hint-container-title">Caution</p>
<p><strong>Flashing the firmware has the potential to brick your device</strong>, requiring relatively inexpensive hardware and some technical knowledge to recover. Not all boards can be tested prior to release, and even then slight differences in hardware can lead to unforeseen failures. If you do not have the ability to recover from a bad flash, <strong>you are taking a risk</strong>. Flashing Full ROM firmware will remove your ability to run ChromeOS.</p>
</div>
<p>To convert your machine from Google's firmware and ChromeOS to Coreboot and AltOS (Linux/Windows/macOS), you will need to first perform the following steps:</p>
<ol>
<li><RouteLink to="/docs/firmware/developer-mode.html">Enable developer mode</RouteLink>.</li>
<li><RouteLink to="/docs/firmware/write-protect.html">Disable write protect</RouteLink> (If flashing UEFI).</li>
<li>Run <a href="https://mrchromebox.tech/#fwscript" target="_blank" rel="noopener noreferrer">MrChromebox's firmware utility script</a> within VT-2 (<code v-pre>ctrl</code> + <code v-pre>alt</code> + <code v-pre>f2</code> (right arrow)).
<ul>
<li>Type <code v-pre>cd; curl -LO mrchromebox.tech/firmware-util.sh &amp;&amp; sudo bash firmware-util.sh</code> and press Enter.</li>
<li>If you encounter certificate related errors when downloading the script from ChromeOS, then add <code v-pre>-k</code> to the <code v-pre>curl</code> and script command to bypass SSL certificate checking as so:
<ul>
<li><code v-pre>cd; curl -LOk mrchromebox.tech/firmware-util.sh &amp;&amp; sudo bash firmware-util.sh</code></li>
</ul>
</li>
</ul>
</li>
<li>If you are flashing Full ROM, the firmware script will prompt you to make a backup of your stock firmware. <strong>Store it in a safe place</strong> (Google Drive, another PC, etc), as reverting to stock firmware without a backup is <strong>very difficult</strong>.</li>
<li>If there's no error, shutdown the device without worry.</li>
</ol>
<div class="hint-container caution">
<p class="hint-container-title">Caution</p>
<p>If flashing fails for UEFI, <strong>DO NOT SHUTDOWN</strong> and immediately attempt to restore stock firmware.
Otherwise, shutdown without worry.</p>
</div>
<div class="hint-container tip">
<p class="hint-container-title">Tips</p>
<p>If you unplugged the battery to <RouteLink to="/docs/firmware/battery.html">disable write protect</RouteLink>, you can plug it back in now. All flashes after this will not require you to unplug the battery.</p>
</div>
<h2 id="booting" tabindex="-1"><a class="header-anchor" href="#booting"><span>Booting</span></a></h2>
<h3 id="uefi" tabindex="-1"><a class="header-anchor" href="#uefi"><span>UEFI</span></a></h3>
<p>It can take up to a minute for display to come up on first POST. <strong>Do not interrupt the first boot.</strong></p>
<h3 id="rw-legacy" tabindex="-1"><a class="header-anchor" href="#rw-legacy"><span>RW_LEGACY</span></a></h3>
<p>On the developer mode boot screen, press Ctrl+L. If a selection appears, pick &quot;Tianocore&quot;.</p>
<p>If you are met with a white &quot;Alternative Firmware Menu&quot; screen (after pressing Ctrl+L), press 2 to boot from USB or `0 to boot from internal storage (where you installed your Linux OS).</p>
<p>Further boot options can be accessed after you have entered a number, by pressing ESC as signified by the booting screen. If you wish to access these options before you've installed an OS, press 0 then ESC.</p>
</div></template>


