<template><div><h1 id="unbricking-with-a-flipper-zero" tabindex="-1"><a class="header-anchor" href="#unbricking-with-a-flipper-zero"><span>Unbricking with a Flipper Zero</span></a></h1>
<p>Yes, this is possible.</p>
<h2 id="prerequisites" tabindex="-1"><a class="header-anchor" href="#prerequisites"><span>Prerequisites</span></a></h2>
<ul>
<li>Your brain (please)</li>
<li>A Flipper Zero with up-to-date firmware
<ul>
<li>It doesn't matter if you are using stock or custom FW.</li>
</ul>
</li>
<li>A SOIC8 clip chip
<ul>
<li>You can buy a cheap Chinese one <a href="https://www.amazon.com/Ximimark-SOIC8-Socket-Adpter-Programmer/dp/B07BRSVRXV" target="_blank" rel="noopener noreferrer">here</a></li>
</ul>
</li>
<li>A screwdriver / pry tool to open your Chromebook</li>
<li>A copy of your stock ROM</li>
<li>A way to copy files onto your Flipper Zero</li>
</ul>
<h2 id="preparations" tabindex="-1"><a class="header-anchor" href="#preparations"><span>Preparations</span></a></h2>
<ol>
<li>Locate the SPI flash chip (See <RouteLink to="/docs/unbricking/">Unbricking Page</RouteLink>, under &quot;Hardware Disassembly&quot;)</li>
<li>A understanding that this may not work on your system</li>
</ol>
<div class="hint-container caution">
<p class="hint-container-title">Caution</p>
<p><strong>You will NOT be able to preserve the VPD using this method! This will not apply to you if flashing via your backup ROM.</strong> Read the <RouteLink to="/docs/unbricking/">Unbricking Page</RouteLink> for more information.</p>
</div>
<h3 id="step-1" tabindex="-1"><a class="header-anchor" href="#step-1"><span>Step 1</span></a></h3>
<p>Obtain your stock ROM and rename it to <code v-pre>.bin</code>. For this guide, I will be using qFlipper. Your rom file should now look like this:</p>
<p><img src="/flipperunbrick/stockfirmware.png" alt="binexample"></p>
<h3 id="step-2" tabindex="-1"><a class="header-anchor" href="#step-2"><span>Step 2</span></a></h3>
<p>Locate the <code v-pre>spi_mem_manager</code> folder under the path <code v-pre>SD Card/apps_data/spi_mem_manager/</code>. Copy the .bin file to this newly made folder.</p>
<div class="hint-container tip">
<p class="hint-container-title">Tips</p>
<p>You might see a file size warning. It is safe to ignore it, only files that are 4GB+ will have issues transferring.</p>
</div>
<p>Your <code v-pre>spi_mem_manager/</code> folder should now look like this:</p>
<p><img src="/flipperunbrick/spi_mem_manager.png" alt="image"></p>
<h3 id="step-3" tabindex="-1"><a class="header-anchor" href="#step-3"><span>Step 3</span></a></h3>
<p>On your Flipper Zero, go to <code v-pre>Apps -&gt; GPIO -&gt; SPI Mem Manager -&gt; Saved</code>. You should see your backup .bin. Verify that the file is there, then proceed to the next step.</p>
<h3 id="step-4" tabindex="-1"><a class="header-anchor" href="#step-4"><span>Step 4</span></a></h3>
<p>Connect your SOIC clip chip to your GPIO pins now. See the image below for the wire setup.</p>
<p><img src="/flipperunbrick/GPIOSetup.png" alt="image"></p>
<h3 id="step-5" tabindex="-1"><a class="header-anchor" href="#step-5"><span>Step 5</span></a></h3>
<p>Go back to <code v-pre>Apps -&gt; GPIO -&gt; SPI Mem Manager -&gt; Saved -&gt; Yourfilename.bin</code>. Open the file, then select the option to &quot;Write&quot;.</p>
<h3 id="step-6" tabindex="-1"><a class="header-anchor" href="#step-6"><span>Step 6</span></a></h3>
<p>Assuming all went well, you should no longer have a bricked system.</p>
</div></template>


