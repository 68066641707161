<template><div><h1 id="recovery-mode" tabindex="-1"><a class="header-anchor" href="#recovery-mode"><span>Recovery Mode</span></a></h1>
<p>Recovery mode is used to restore the OS and RW regions of the firmware. It gets triggered when an issue with the device is detected, or you manually start it.</p>
<div class="hint-container tip">
<p class="hint-container-title">Tips</p>
<p>Your data is unaffected in recovery mode unless you use a recovery image or enter <RouteLink to="/docs/firmware/entering-developer-mode.html">developer mode</RouteLink>.</p>
</div>
<div class="hint-container tip">
<p class="hint-container-title">NOTE</p>
<p>Images sourced from <a href="https://support.google.com/chrome/a/answer/1360642?sjid=16957732159917599441-NC#chromebox&amp;zippy=%2Cwipe-a-chromebox" target="_blank" rel="noopener noreferrer">Google Support</a> and <a href="https://www.asus.com/support/FAQ/1039185/" target="_blank" rel="noopener noreferrer">Asus Support</a>.</p>
</div>
<div class="hint-container caution">
<p class="hint-container-title">Caution</p>
<p>Do not continue if your device is currently owned or enrolled by a organization, such as a school or employer. Unless you were given permission by your organization, its a crime.</p>
</div>
<h2 id="entering-recovery-mode" tabindex="-1"><a class="header-anchor" href="#entering-recovery-mode"><span>Entering Recovery Mode</span></a></h2>
<h3 id="chromebooks" tabindex="-1"><a class="header-anchor" href="#chromebooks"><span>Chromebooks</span></a></h3>
<p>Press <code v-pre>ESC</code> + <code v-pre>Refresh</code> (arrow icon), + <code v-pre>Power</code> at the same time. This should bring your system into recovery mode.</p>
<ul>
<li>For some devices the power button is on the side.</li>
<li>Some devices may need to hold down the <code v-pre>Fullscreen</code> button (four corners icon) instead of the <code v-pre>Refresh</code> button.</li>
</ul>
<img src="/recovery/recovery-keyboard-hint.png" width=500 alt="Chromebook Keyboard">
<h3 id="chromeboxes" tabindex="-1"><a class="header-anchor" href="#chromeboxes"><span>Chromeboxes</span></a></h3>
<p>Find the recovery button hole. A reference of what to look for is below.</p>
<img src="/recovery/recovery-chromebox-hint.png" alt="Recovery Button Hole">
<p>When you do, use something like a paper clip to hold the button down, then turn on the device. This should bring your system into recovery mode.
You can stop holding down the button at this point.</p>
<h3 id="tablets" tabindex="-1"><a class="header-anchor" href="#tablets"><span>Tablets</span></a></h3>
<p>Hold down <code v-pre>Volume Up</code> + <code v-pre>Volume Down</code> + <code v-pre>Power</code> for 10 seconds, then release. This should bring your system into recovery mode.</p>
<img src="/recovery/recovery-tablet-hint.png" width=500 alt="tablet Combo">
</div></template>


