<template><div><h1 id="bug-reporting" tabindex="-1"><a class="header-anchor" href="#bug-reporting"><span>Bug Reporting</span></a></h1>
<p>So you find a bug in one of our projects, and want to report it. It's faily simple,</p>
<ol>
<li>Use your brain (please)</li>
<li>Open a issue in our <a href="https://github.com/chrultrabook/bugtracker/issues" target="_blank" rel="noopener noreferrer">bugtracker</a></li>
<li>Be descriptive. Describe what the issue is, steps to reproduce, affected devices, etc etc.
<ul>
<li>If you need help, a generic bug report template is available.</li>
</ul>
</li>
<li>Wait patiently for a contributor to fix the issue.
<ul>
<li>Please keep in mind the majority of us contributors have other things going on in our lives.</li>
</ul>
</li>
</ol>
<br>
<div class="hint-container tip">
<p class="hint-container-title">Tips</p>
<p>Do not submit personal help requests in the bugtracker.</p>
</div>
</div></template>


