<template><div><h1 id="installing-macos" tabindex="-1"><a class="header-anchor" href="#installing-macos"><span>Installing macOS</span></a></h1>
<p>Before we begin, it's important to know whether your Chromebook is even supported:</p>
<ul>
<li>ARM/AMD CPUs are not supported.</li>
<li>Intel Celeron / Pentium CPUs are not supported.</li>
<li>Tiger Lake (11th gen) CPUs and newer are not supported.</li>
<li>You need at least 32GB of eMMC storage.</li>
</ul>
<p><strong>You will also need:</strong></p>
<ul>
<li>Patience
<ul>
<li>This is not a one click setup thing. It usually takes one or more weeks to have a stable hack.</li>
</ul>
</li>
<li>A USB drive
<ul>
<li>4 gigabytes or more for a online installer.</li>
<li>32 gigabytes or more for offline installer.</li>
</ul>
</li>
<li>The ability to use a command line
<ul>
<li>We will not help you if you do not know how to <code v-pre>cd</code> to a directory.</li>
</ul>
</li>
</ul>
<h2 id="tested-devices" tabindex="-1"><a class="header-anchor" href="#tested-devices"><span>Tested Devices</span></a></h2>
<div class="hint-container tip">
<p class="hint-container-title">Tips</p>
<p>This list is incomplete. Feel free to improve it.</p>
</div>
<ul>
<li>Asus C425 (LEONA)</li>
<li>Asus C434 (SHYVANA)</li>
<li>Asus C433 (SHYVANA)</li>
<li>Acer C720 / C720P (PEPPY)</li>
<li>Dell Chromebook 13 7310 (LULU)</li>
<li>Google Pixelbook (2017) (EVE)</li>
<li>Google Pixel Slate (NOCTURNE)</li>
<li>Google Pixelbook Go (2019) (ATLAS)</li>
<li>Dell Latitude 7410 Chromebook Enterprise (DRALLION)</li>
<li>Asus Chromebox 2 (CN62) (GUADO)</li>
<li>HP Chromebook x360 14c (DRAGONAIR)</li>
<li>Acer Chromebook Spin 713 (CP713-2W) (KLED)</li>
<li>Acer Chromebook 14 for Work (LARS)</li>
<li>Acer Chromebook 11 (C771/C771T) (LARS)</li>
</ul>
<p><strong>The following devices have dedicated guides written for them:</strong></p>
<ul>
<li>
<p><a href="https://github.com/meghan06/ChromebookOSX" target="_blank" rel="noopener noreferrer">ChromebookOSX</a>:</p>
<ul>
<li>Asus C425 (LEONA)</li>
<li>Asus C434 (SHYVANA)</li>
<li>Asus C433 (SHYVANA)</li>
</ul>
</li>
<li>
<p><a href="https://github.com/olm3ca/PixelbookOSX" target="_blank" rel="noopener noreferrer">PixelbookOSX</a>:</p>
<ul>
<li>Google Pixelbook (2017) (EVE)</li>
<li>Google Pixel Slate (NOCTURNE)</li>
<li>Google Pixelbook Go (2019) (ATLAS)</li>
</ul>
</li>
<li>
<p><a href="https://github.com/mine-man3000/macOS-Dragonair" target="_blank" rel="noopener noreferrer">macOS-Dragonair</a>:</p>
<ul>
<li>HP Chromebook x360 14c (DRAGONAIR)</li>
</ul>
</li>
<li>
<p><a href="https://isi95010.github.io/LuluMacOS/" target="_blank" rel="noopener noreferrer">LuluMacOS</a></p>
<ul>
<li>Dell Chromebook 13 7310 (LULU)</li>
</ul>
</li>
</ul>
<h2 id="custom-firmware" tabindex="-1"><a class="header-anchor" href="#custom-firmware"><span>Custom Firmware</span></a></h2>
<p>It is recommended to <a href="macos-firmware">flash Coreboot firmware</a> with the Management Engine Interface enabled on Skylake and newer.<br>
Without the ME interface enabled, the following bugs can occur after waking from sleep:</p>
<ul>
<li>Blank Electron / Chromium apps</li>
<li>Buggy video playback in web browsers like Firefox or Safari</li>
<li>Display/Wallpaper settings in SysPref/SysSettings</li>
<li>Logging out freezes your system</li>
</ul>
<h2 id="installation" tabindex="-1"><a class="header-anchor" href="#installation"><span>Installation</span></a></h2>
<p>Start by following the <a href="https://dortania.github.io/OpenCore-Install-Guide" target="_blank" rel="noopener noreferrer">Dortania guide</a> for your CPU generation.
Amber Lake Chromebooks should follow config.plist setup for Kaby Lake.<br>
Add the following SSDTs, kexts and modifications as needed:</p>
<h3 id="config-plist-fixes" tabindex="-1"><a class="header-anchor" href="#config-plist-fixes"><span>config.plist Fixes</span></a></h3>
<ul>
<li>Disable <code v-pre>Booter-&gt;Quirks-&gt;DevirtualiseMmio</code>: Fixes NVRAM and other runtime EFI services</li>
</ul>
<h3 id="ssdts-and-acpi-fixes" tabindex="-1"><a class="header-anchor" href="#ssdts-and-acpi-fixes"><span>SSDTs and ACPI Fixes</span></a></h3>
<ul>
<li><a href="https://github.com/meghan06/croscorebootpatch" target="_blank" rel="noopener noreferrer">croscorebootpatch</a>: Fixes freeze during boot when using coreboot 4.20 and newer. Not needed when custom rom is used.</li>
<li><a href="https://github.com/1Revenger1/Acer-Spin-713-Hackintosh/blob/main/src/ACPI/SSDT-ChromeKeys.dsl" target="_blank" rel="noopener noreferrer">Keyboard Map</a>: Maps FNx keys to ChromeOS mapping. This is an example, you may need to do your own mapping.
<ul>
<li>Will need to be compiled with either <code v-pre>iASL</code> or <code v-pre>MaciASL</code>.</li>
</ul>
</li>
<li><a href="https://github.com/acidanthera/OpenCorePkg/blob/master/Docs/AcpiSamples/Source/SSDT-ALS0.dsl" target="_blank" rel="noopener noreferrer">Fake Ambient Light Sensor</a>: Creates a fake ambient light sensor which is needed by macOS to recognize the keyboard backlight.
<ul>
<li>This is only needed if your device does not come with a light sensor.</li>
<li>A pre-compiled version comes in OpenCore's release zip under <code v-pre>Docs/AcpiSamples/Binaries/SSDT-ASL0.aml</code></li>
</ul>
</li>
</ul>
<h3 id="kexts" tabindex="-1"><a class="header-anchor" href="#kexts"><span>Kexts</span></a></h3>
<ul>
<li><a href="https://github.com/acidanthera/EmeraldSDHC/releases" target="_blank" rel="noopener noreferrer">EmeraldSDHC.kext</a>: eMMC driver</li>
<li><a href="https://github.com/1Revenger1/VoodooPS2/releases" target="_blank" rel="noopener noreferrer">VoodooPS2.kext</a>: Fork of Acidanthera's VoodooPS2 which allows mapping keyboard brightness and other useful keys</li>
<li><a href="https://github.com/1Revenger1/CrosEC/releases" target="_blank" rel="noopener noreferrer">CrosEC.kext</a>: Adds keyboard brightness, tablet mode, and other functionality</li>
</ul>
<h4 id="snapshot-cmd-r-or-ctrl-r-your-config-plist-after-making-modifications" tabindex="-1"><a class="header-anchor" href="#snapshot-cmd-r-or-ctrl-r-your-config-plist-after-making-modifications"><span>Snapshot (cmd + r) or (ctrl + r) your <code v-pre>config.plist</code> after making modifications</span></a></h4>
<div class="hint-container caution">
<p class="hint-container-title">Caution</p>
<p>If you dualboot with the SSDTs mentioned above, you might run into issues on other OSes. An <code v-pre>OSI_</code> check is not present in these SSDTs.</p>
</div>
</div></template>


